<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout v-if="!otpInput" wrap justify-center>
      <v-flex pa-4 xs12 sm12 md6>
        <v-card :outlined="true">
          <v-layout pa-4>
            <v-flex text-left>
              <span style="font-size:16px;font-weight:500">Profile</span>
            </v-flex>
            <v-flex v-if="!editProfile" text-right>
              <v-btn
                text
                class="text-capitalize"
                dark
                style="font-size:16px;font-weight:500"
                :ripple="false"
                color="#26af82"
                @click="otpInput=true"
              >Edit</v-btn>
              <!-- <span style="font-size:16px;font-weight:500">Edit</span> -->
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex px-4 pb-4>
              <v-layout align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A;">Username</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span style="font-size:16px;color:#090909;">{{userName}}</span>
                </v-flex>
                <v-flex v-if="editProfile" text-left>
                  <v-text-field
                    ref="userName"
                    v-model="userName"
                    :rules="[rules.required,rules.username]"
                    :error-messages="msg_username"
                    color="#26af82"
                    required
                    height="20"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">Phone Number</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span>{{countryCode}}{{ phoneNo}}</span>
                </v-flex>
                <v-flex v-if="editProfile">
                  <v-layout xs12 md12 sm12 wrap justify-center>
                    <v-flex xs3 md3 pl-5>
                      <v-select
                        v-model="countryCode"
                        :items="countryCodes"
                        :rules="[rules.required]"
                        color="#26af82"
                        required
                        height="20"
                      ></v-select>
                    </v-flex>
                    <v-flex xs9 md9 pl-5>
                      <v-text-field
                        ref="phoneNo"
                        v-model="phoneNo"
                        :rules="[rules.required,rules.phone]"
                        :error-messages="msg_phoneno"
                        color="#26af82"
                        required
                        height="20"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">Mail ID</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span>{{mailId}}</span>
                </v-flex>
                <v-flex v-if="editProfile" text-left>
                  <v-text-field
                    ref="mailId"
                    v-model="mailId"
                    :rules="[rules.required,rules.email]"
                    :error-messages="msg_mailId"
                    color="#26af82"
                    required
                    height="20"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span v-if="!editProfile" style="color:#6A6A6A">Password</span>
                  <span v-if="editProfile" style="color:#6A6A6A">New Password</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <v-layout>
                    <v-flex>
                      <span>●●●●●●●●</span>
                    </v-flex>
                  </v-layout>
                  <v-layout my-n2>
                    <v-flex>
                      <span style="font-size:10px">Last Changed {{passworddate}}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 md8 v-if="editProfile" text-left>
                  <v-text-field
                    ref="password"
                    color="#26af82"
                    v-model="password"
                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordShow ? 'text' : 'password'"
                    @click:append="passwordShow = !passwordShow"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- <v-divider v-if="editProfile"></v-divider> -->
              <!-- <v-layout v-if="editProfile" pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">Confirm Password</span>
                </v-flex>
                <v-flex xs12 md8 v-if="editProfile" text-left>
                  <v-text-field
                    ref="confPassword"
                    v-model="confPassword"
                    :rules="[rules.required,rules.min,rules.password]"
                    :append-icon="passwordShow2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordShow2 ? 'text' : 'password'"
                    @click:append="passwordShow2 = !passwordShow2"
                    :error-messages="msg_password"
                    color="#26af82"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>-->
            </v-flex>
          </v-layout>
          <v-layout pa-4 v-if="editProfile" wrap justify-center>
            <v-flex text-right>
              <v-btn
                text
                class="text-capitalize"
                dark
                :ripple="false"
                color="#26af82"
                @click="submit"
              >Submit</v-btn>
              <v-btn
                text
                class="text-capitalize"
                dark
                :ripple="false"
                color="#26af82"
                @click="editProfile=false"
              >Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="otpInput" wrap justify-center>
      <v-flex pa-4 xs12 sm12 md6>
        <v-card :outlined="true">
          <otpReader @stepper="winStepper" />
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import otpReader from "./otpReader";
export default {
  // props: ["profileData"],
  components: {
    VueElementLoading,
    otpReader
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      profileData: [],

      userName: null,
      countryCode: null,
      phoneNo: null,
      mailId: null,
      countryCodes: ["+61", "+65", "+91"],
      passworddate: null,

      password: null,
      confPassword: null,
      passwordShow: false,
      passwordShow2: false,

      formHasErrors: false,

      msg_username: null,
      msg_phoneno: null,
      msg_mailId: null,
      msg_password: null,
      err_username: false,
      err_phoneno: false,
      err_mailid: false,
      err_password: false,

      userId: null,
      rules: {
        required: value => !!value || "Required",
        min: value => (!!value && value.length >= 8) || "Min 8 characters",
        username: value => (!!value && value.length >= 4) || "Min 4 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid Mail ID";
        },
        phone: value => {
          const pattern = /^[0-9+]*$/;
          return pattern.test(value) || "Invalid Phone Number";
        },
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;
          return (
            pattern.test(value) ||
            "Must contain atleast one uppercase, one lowercase,one digit and one special characters"
          );
        }
      },
      editProfile: false,
      otpInput: false
    };
  },
  beforeMount() {
    this.getProfile();
  },
  watch: {
    userName: function() {
      axios({
        method: "GET",
        url: "/user/check/username",
        params: {
          username: this.userName,
          id: this.userId
        }
      }).then(response => {
        if (response.data.status == false) {
          this.err_username = true;
          this.msg_username = response.data.msg;
        } else {
          this.err_username = false;
          this.msg_username = null;
        }
      });
    },
    phoneNo: function() {
      var data = {};
      data["phone"] = this.phoneNo;
      data["prefix"] = this.countryCode;
      data["id"] = this.userId;
      axios({
        method: "POST",
        url: "/user/check/phone",
        data: data
      }).then(response => {
        if (response.data.status == false) {
          this.err_phoneno = true;
          this.msg_phoneno = response.data.msg;
        } else {
          this.err_phoneno = false;
          this.msg_phoneno = null;
        }
      });
    },
    countryCode: function() {
      var data = {};
      data["phone"] = this.phoneNo;
      data["prefix"] = this.countryCode;
      data["id"] = this.userId;
      axios({
        method: "POST",
        url: "/user/check/phone",
        data: data
      }).then(response => {
        if (response.data.status == false) {
          this.msg_phoneno = response.data.msg;
          this.err_phoneno = true;
        } else {
          this.formHasErrors = false;
          this.err_phoneno = null;
        }
      });
    },
    mailId: function() {
      axios({
        method: "GET",
        url: "/user/check/email",
        params: {
          email: this.mailId,
          id: this.userId
        }
      }).then(response => {
        if (response.data.status == false) {
          this.err_mailid = true;
          this.msg_mailId = response.data.msg;
        } else {
          this.err_mailid = false;
          this.msg_mailId = null;
        }
      });
    }
    // confPassword: function() {
    //   if (this.password != this.confPassword)
    //     this.msg_password = "Passwords not matching";
    //   else this.msg_password = null;
    // },
    // password: function() {
    //   // if (this.confPassword) {
    //   //   if (this.password != this.confPassword)
    //   //     this.msg_password = "Passwords not matching";
    //   //   else this.msg_password = null;
    //   // }

    //   axios({
    //     method: "GET",
    //     url: "/user/check/password",
    //     params: {
    //       password: this.password,
    //       id: this.userId
    //     }
    //   }).then(response => {
    //     if (response.data.status == false) {
    //       this.err_password = true;
    //       this.msg_password = response.data.msg;
    //     } else {
    //       this.err_password = false;
    //       this.msg_password = null;
    //     }
    //   });
    // }
  },
  computed: {
    form() {
      return {
        userName: this.userName,
        phoneNo: this.phoneNo,
        mailId: this.mailId,
        password: this.password
        // confPassword: this.confPassword
      };
    }
  },
  methods: {
    winStepper(window_data) {
      this.otpInput = false;
      this.editProfile = window_data.allowEdit;
    },
    getProfile() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          // console.log(response.data.data);
          this.profileData = response.data.data;
          this.userName = response.data.data.username;
          this.countryCode = response.data.data.phonePrefix;
          this.phoneNo = response.data.data.phone;
          this.mailId = response.data.data.email;
          this.userId = response.data.data._id;
          var dt = response.data.data.log.prevpass1.chagedDate;
          dt = new Date(dt);
          dt = dt.toString();
          dt = dt.slice(4, 15);
          this.passworddate = dt;
          this.appLoading = false;
        })
        .catch(err => {
          this.msg = err;
          this.msg = "Network Error";
          this.appLoading = false;
        });
    },
    submit() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach(f => {
        if (f == "password" && !this.password) {
          return;
        }
        if (!this.form[f]) {
          this.formHasErrors = true;
        }
        var ret = this.$refs[f].validate(true);
        if (!ret) this.formHasErrors = true;
      });
      if (this.err_username) this.formHasErrors = true;
      if (this.err_phoneno) this.formHasErrors = true;
      if (this.err_mailid) this.formHasErrors = true;
      if (this.err_password) this.formHasErrors = true;
      if (!this.formHasErrors) {
        this.appUpdate();
      } else {
        this.msg = "Please Validate Input";
        this.showSnackBar = true;
      }
    },
    appUpdate() {
      this.appLoading = true;
      this.profileData = {};
      this.profileData.username = this.userName;
      this.profileData.phonePrefix = this.countryCode;
      this.profileData.phone = this.phoneNo;
      this.profileData.email = this.mailId;
      this.profileData.id = this.userId;
      this.profileData.password = this.password;
      this.profileData.completeprofile = true;
      axios({
        method: "POST",
        url: "/user/Edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: this.profileData
      })
        .then(response => {
          this.getProfile();
          this.password = null;
          this.appLoading = false;
          this.editProfile = false;
          this.showSnackBar = true;
          this.msg = response.data.msg;
        })
        .catch(err => {
          this.appLoading = false;
          this.showSnackBar = true;
          this.msg = err.response.data.msg;
        });
    }
  }
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  /* border-radius: 4px; */
  border: 1px solid #26af82;
  outline: 0;
  border-width: 0 0 1px;
  /* border-color: blue; */
  text-align: "center";
  padding-left: 12px;
}

.error {
  border: 1px solid red !important;
}
</style>