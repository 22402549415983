<template>
  <div id="app_signup">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-layout wrap justify-space-around>
      <v-flex xs12 md12 pa-8>
        <v-card id="app_height" outlined tile>
          <v-layout wrap pt-4 justify-center>
            <v-flex xs12 md2 align="center">
              <router-link to="/">
                <v-img src="../../assets/icons/back.png" aspect-ratio="1" contain height="50px"></v-img>
              </router-link>
            </v-flex>
            <v-flex xs12 md8 text-center align-self-end>
              <span class="login_head">Welcome to the Ecosystem Investment Platform</span>
            </v-flex>
            <v-flex md2></v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex text-center>
              <span
                style="font-size:12px;font-weight:400;color:#a9a9a9;"
              >You have signed in as Administrator</span>
            </v-flex>
          </v-layout>
          {{msg}}
          <profileDetails v-bind:profileData="profileData" />
          <registrationDetails v-bind:profileData="profileData" />
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import profileDetails from "./../Profile/profileDetails";
import registrationDetails from "./../Profile/registrationDetails";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

export default {
  components: {
    profileDetails,
    registrationDetails,
    VueElementLoading
  },
  data() {
    return {
      winStep: 0,
      msg: null,
      profileData: {},
      appLoading: false
    };
  },
  beforeMount() {
    // this.getProfile();
  },
  methods: {
    winStepper(window_data) {
      this.winStep = window_data.winStep;
    },
    getProfile() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          // console.log(response.data.data);
          this.profileData = response.data.data;
          this.appLoading = false;
        })
        .catch(err => {
          // console.log(err);
          this.msg = err;
          this.msg = "Network Error";
        });
    }
  }
};
</script>
<style scoped>
#app_signup {
  background-image: url("../../assets/home_header.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
  }
}
</style>