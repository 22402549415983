<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex pa-4 xs12 sm12 md6>
        <v-card :outlined="true">
          <v-layout pa-4>
            <v-flex text-left>
              <span style="font-size:16px;font-weight:500">Registration Details</span>
            </v-flex>
            <v-flex v-if="!editProfile" text-right>
              <v-btn
                text
                class="text-capitalize"
                dark
                style="font-size:16px;font-weight:500"
                :ripple="false"
                color="#26af82"
                @click="editProfile=true"
              >Edit</v-btn>
              <!-- <span style="font-size:16px;font-weight:500">Edit</span> -->
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex px-4 pb-4>
              <v-layout>
                <v-flex pr-2>
                  <v-layout align="center">
                    <v-flex xs12 sm12 md4 text-left>
                      <span style="color:#6A6A6A;">First Name</span>
                    </v-flex>
                    <v-flex v-if="!editProfile" text-left>
                      <span style="font-size:16px;color:#090909;">{{firstName}}</span>
                    </v-flex>
                    <v-flex v-if="editProfile" text-left>
                      <v-text-field
                        ref="firstName"
                        v-model="firstName"
                        :rules="[rules.required]"
                        color="#26af82"
                        required
                        height="20"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex pl-2>
                  <v-layout align="center">
                    <v-flex xs12 sm12 md4 text-left>
                      <span style="color:#6A6A6A;">Last Name</span>
                    </v-flex>
                    <v-flex v-if="!editProfile" text-left>
                      <span style="font-size:16px;color:#090909;">{{lastName}}</span>
                    </v-flex>
                    <v-flex v-if="editProfile" text-left>
                      <v-text-field
                        ref="lastName"
                        v-model="lastName"
                        :rules="[rules.required]"
                        color="#26af82"
                        required
                        height="20"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4>
                <v-flex text-left>
                  <span style="font-size:16px;font-weight:500">Address</span>
                </v-flex>
              </v-layout>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">Address-1</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span>{{address}}</span>
                </v-flex>
                <v-flex v-if="editProfile" text-left>
                  <v-text-field
                    ref="address"
                    v-model="address"
                    :rules="[rules.required]"
                    color="#26af82"
                    required
                    height="20"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">Address-2</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span>{{address2}}</span>
                </v-flex>
                <v-flex v-if="editProfile" text-left>
                  <v-text-field
                    ref="address2"
                    v-model="address2"
                    :rules="[rules.required]"
                    color="#26af82"
                    required
                    height="20"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">Address-2</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span>{{address3}}</span>
                </v-flex>
                <v-flex v-if="editProfile" text-left>
                  <v-text-field ref="address3" v-model="address3" color="#26af82" height="20"></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">City</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span>{{city}}</span>
                </v-flex>
                <v-flex v-if="editProfile" text-left>
                  <v-text-field
                    ref="city"
                    v-model="city"
                    :rules="[rules.required]"
                    color="#26af82"
                    required
                    height="20"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">Postcode</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span>{{postcode}}</span>
                </v-flex>
                <v-flex v-if="editProfile" text-left>
                  <v-text-field
                    ref="postcode"
                    v-model="postcode"
                    :rules="[rules.required,rules.postalcode]"
                    color="#26af82"
                    required
                    height="20"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 align="center">
                <v-flex xs12 sm12 md4 text-left>
                  <span style="color:#6A6A6A">Country</span>
                </v-flex>
                <v-flex v-if="!editProfile" text-left>
                  <span>{{country}}</span>
                </v-flex>
                <v-flex v-if="editProfile" text-left>
                  <v-select
                    :items="Countries"
                    ref="country"
                    v-model="country"
                    :rules="[rules.required]"
                    color="#26af82"
                    height="20"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout pa-4 v-if="editProfile" wrap justify-center>
            <v-flex text-right>
              <v-btn
                text
                class="text-capitalize"
                dark
                :ripple="false"
                color="#26af82"
                @click="submit"
              >Submit</v-btn>
              <v-btn
                text
                class="text-capitalize"
                dark
                :ripple="false"
                color="#26af82"
                @click="editProfile=false"
              >Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  // props: ["profileData"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      profileData: [],
      firstName: null,
      lastName: null,
      address: null,
      address2: null,
      address3: null,
      city: null,
      postcode: null,
      country: null,
      editProfile: false,
      userId: null,
      Countries: ["Australia", "India", "Singapore"],
      rules: {
        required: value => !!value || "Required",
        min: value => (!!value && value.length >= 8) || "Min 8 characters",
        postalcode: value => {
          const pattern = /^[0-9]{6}$/;
          return pattern.test(value) || "Invalid Postcode";
        }
      },
      formHasErrors: false
    };
  },
  computed: {
    form() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.address,
        address2: this.address2,
        city: this.city,
        postcode: this.postcode,
        country: this.country
      };
    }
  },
  beforeMount() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          // console.log(response.data.data);
          this.profileData = response.data.data;
          this.userId = response.data.data._id;
          this.firstName = response.data.data.individual.firstName;
          this.lastName = response.data.data.individual.lastName;
          this.address = response.data.data.address.address;
          this.address2 = response.data.data.address.address2;
          this.address3 = response.data.data.address.address3;
          this.city = response.data.data.address.city;
          this.postcode = response.data.data.address.postcode;
          this.country = response.data.data.address.country;
          // console.log("userid", response);
          this.appLoading = false;
        })
        .catch(err => {
          this.msg = err;
          this.msg = "Network Error";
          this.appLoading = false;
        });
    },
    submit() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true;
        var ret = this.$refs[f].validate(true);
        if (!ret) this.formHasErrors = true;
      });
      if (!this.formHasErrors) {
        this.appUpdate();
      } else {
        this.msg = "Please Validate Input";
        this.showSnackBar = true;
      }
    },
    appUpdate() {
      this.appLoading = true;
      this.profileData = {};
      this.profileData.firstName = this.firstName;
      this.profileData.lastName = this.lastName;
      this.profileData.address = this.address;
      this.profileData.address2 = this.address2;
      this.profileData.address3 = this.address3;
      this.profileData.city = this.city;
      this.profileData.postcode = this.postcode;
      this.profileData.country = this.country;
      this.profileData.id = this.userId;
      this.profileData.completeprofile = true;
      axios({
        method: "POST",
        url: "/user/Edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: this.profileData
      })
        .then(response => {
          this.editProfile = false;
          this.getProfile();
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    }
  }
};
</script>

<style scoped>
.v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
</style>