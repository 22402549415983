<template>
  <div>
    <v-snackbar v-model="showotpsnack" color="#00a972" right :timeout="5000">
      One-time PIN send Successfully
      <v-btn text @click="showotpsnack = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-layout pa-4 justify-center>
      <v-flex xs12 sm12 md12 text-center>Enter the One time Pin received in your phone</v-flex>
    </v-layout>
    <v-layout justify-center pt-3>
      <v-flex xs12 md6 text-center pl-4>
        <vie-otp-input
          inputClasses="otp-input"
          :numInputs="5"
          separator
          :shouldAutoFocus="true"
          @on-complete="validateOTP"
        />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md6 pl-6>
        <span
          v-if="!otpexpired"
          style="font-size:14px;font-weight:300;color:#a9a9a9;"
        >Your one-time PIN will expire in {{ timeLeft }}</span>
        <span v-else style="font-size:14px;font-weight:300;color:red;">Your one-time PIN has expired</span>
      </v-flex>
    </v-layout>
    <v-layout v-if="showotpbtn" wrap justify-center>
      <v-flex xs12 md6 pl-6>
        <span
          @click="resendOTP"
          onMouseOver="this.style.cursor='pointer'"
          style="font-size:12px;font-weight:500;color:#a9a9a9;"
        >Resend Code</span>
      </v-flex>
    </v-layout>
    <v-layout v-if="msg" justify-center pt-3>
      <v-flex xs12 md12 text-center>
        <span style="font-size:16px;font-weight:400;color:red;">{{msg}}</span>
      </v-flex>
    </v-layout>
    <v-layout pt-2 wrap justify-center pb-4>
      <v-flex xs12 md12 text-center>
        <v-btn
          text
          class="text-capitalize"
          dark
          :ripple="false"
          color="#26af82"
          @click="submit"
        >Submit</v-btn>
        <v-btn
          @click="closeWindow"
          text
          class="text-capitalize"
          dark
          :ripple="false"
          color="#26af82"
        >Cancel</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";

import VieOtpInput from "@bachdgvn/vue-otp-input";
export default {
  components: {
    VieOtpInput,
    VueElementLoading
  },
  props: ["signupData"],
  data() {
    return {
      OTP: null,
      // OTP: "qscefb",
      OTPVerified: false,
      appLoading: false,
      msg: null,
      showotpbtn: false,
      timeLeft: "00:00",
      intervalTimer: null,
      showotpsnack: false,
      otpexpired: false,
      userId: null
    };
  },
  beforeMount() {
    this.setTime(420);
    this.resendOTP();
  },
  methods: {
    closeWindow() {
      this.$emit("stepper", { closeWindow: true, allowEdit: true });
    },
    validateOTP(value) {
      this.OTP = value;
    },
    resendOTP() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/sendotp",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          id: ""
        }
      })
        .then(response => {
          this.setTime(420);
          if (response.data.status) {
            this.msg = response.data.msg;
            this.msg = null;
            this.userId = response.data.id;
            this.showotpbtn = false;
            this.showotpsnack = true;
            this.appLoading = false;
            this.otpexpired = false;
          } else {
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch(err => {
          this.msg = err;
          this.msg = "Server Unreachable";
          this.appLoading = false;
        });
    },
    submit() {
      this.appLoading = true;
      var verdata = {};
      verdata["otp"] = this.OTP;
      verdata["id"] = this.userId;

      axios({
        method: "POST",
        url: "/user/verifyotp",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: verdata
      })
        .then(response => {
          this.msg = response.data.msg;
          if (response.data.status) {
            this.OTPVerified = true;
            // setTimeout(() => {
            this.$emit("stepper", { closeWindow: true, allowEdit: true });
            // }, 3000);
            this.appLoading = false;
          } else {
            this.appLoading = false;
          }
        })
        .catch(err => {
          this.msg = err;
          this.msg = "Server Unreachable";
          this.appLoading = false;
        });
    },
    setTime(seconds) {
      clearInterval(this.intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.countdown(end);
    },
    countdown(end) {
      this.intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);
        if (secondsLeft == 350) this.showotpbtn = true;
        if (secondsLeft == 0) this.otpexpired = true;

        if (secondsLeft < 0) {
          clearInterval(this.intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;
      var seconds1 = seconds;
      if (seconds < 10) seconds1 = "0" + seconds;
      this.timeLeft = "0" + minutes + " : " + seconds1;
    }
  }
};
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  /* border-radius: 4px; */
  border: 1px solid #26af82;
  outline: 0;
  border-width: 0 0 1px;
  /* border-color: blue; */
  text-align: "center";
  padding-left: 12px;
}

.error {
  border: 1px solid red !important;
}
</style>